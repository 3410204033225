@use './config/' as *;
@forward './main.scss';
@forward './module/static';
@forward './module/static/index';
@forward './module/static/development-team';
.waf-listing {
    .waf-overlay-list {
        grid-auto-flow: column;
        grid-auto-columns: calc(100% - var(--space-6));
        grid-template-columns: unset;
        overflow-x: auto;
    }
}
.waf-static {
    @extend %page-head-blue-bg;
    .waf-body {
        max-width: unset;
    }
}
.waf-common-static {
    .layout-wrapper {
        max-width: unset;
    }
}
.waf-team {
    .waf {
        &-head {
            @extend %d-none;
        }
        &-body {
            margin-top: unset;
        }
    }
    .card {
        border-radius: unset;
        @extend %bg-transparent;
    }
}
@include mq(col-tablet) {
    .waf-static {
        .waf-body {
            max-width: var(--lg-container-max-width);
            margin-top: var(--space-20-neg);
        }
    }
    .waf-listing {
        &.waf-horizontal-list {
            .article-list {
                grid-template-columns: repeat(2, 1fr);
                gap: var(--space-6);
            }
        }
        &.waf-overlay-list {
            .article-list {
                grid-template-columns: repeat(4, 1fr);
                gap: var(--space-6);
            }
        }
    }
    .waf-team {
        .waf-body {
            gap: var(--space-0);
        }
    }
}