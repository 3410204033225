@use '../../config/' as *;
.waf-team {
  padding-block: 0 var(--space-20);
  margin-bottom: 0;
  @extend %development-page-head-bg;
  @extend %accordion-common;
  @extend %member-item;
  .head-wrap {
    padding-bottom: calc(var(--space-24) + var(--space-4));
    @include flex-config(flex, null, null, flex-end);
  }
  .card {
    @extend %p-6-4;
    @extend %border-radius-m;
    @extend %bg-neutral-0;
    &-list {
      @include card-count(1.4, var(--space-4), nowrap);
    }
    &:not(:last-child) {
      @extend %mb-6;
    }
    &-title {
      @extend %mb-8;
      @extend %title-40;
    }
    &-text {
      @extend %body-l;
      + .card-title {
        @extend %mt-20;
      }
    }
    &-thumbnail {
      aspect-ratio: 5/4;
    }
  }
  .meta-origin {
    img {
      width: 2.4rem;
    }
  }
  .waf-body {
    @extend %px-4;
  }
}
.column {
  &-left {
    @extend %mb-10;
    .list {
      @extend %pb-4;
    }
  }
}
.list {
  list-style-type: disc;
  @extend %pl-4;
  &-text {
    @extend %body-l;
  }
}
.link{
  color: var(--primary-50);
  &:hover {
    text-decoration: underline;
  }
}
.accordion-item:not(:last-child) {
  @extend %mb-10;
}
@include mq(col-tablet) {
  .waf-team {
    .head-wrap {
      padding-block: var(--space-0) calc(var(--space-24) + var(--space-14));
    }
    .waf-body {
      padding-inline: 0;
      @include large-wrapper();
    }
    .card {
      padding: var(--space-8);
      &-list {
        @include card-count(4, var(--space-4), wrap);
      }
    }
  }
}
@include mq(col-lg) {
  .waf-team {
    padding-bottom: var(--space-24);
    .card {
      &-title {
        margin-bottom: var(--space-6);
      }
      &-text + .card-title {
        margin-top: var(--space-8);
      }
      &-list {
        > * {
          width: calc(100% / 4 - ((3) * var(--space-4) / 4));
        }
      }
    }
    .waf-body {
      display: grid;
      gap: var(--space-6);
      grid-template-columns: auto 65.07%;
    }
  }
}
@include mq(col-desktop) {
  .waf-team .column-right .card {
    padding: var(--space-12);
  }
}